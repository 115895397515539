
.big-container{
  display:flex;
  align-items:center;
  gap:30px;
}

.container{
  width: 100px;
  height: 100px;
  border:4px solid #fff;
}

.box,.boxx {
  width: 100px;
  height: 100px;
  background-color: skyblue;
}

.boxx{
    background-color: red;
}